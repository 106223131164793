import React, { useEffect, useState } from 'react';

const Footer: React.FC = () => {

    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
        setIsMd(mediaQuery.matches);
        };

        mediaQuery.addListener(updateIsMd);
        return () => {
        mediaQuery.removeListener(updateIsMd);
        };
    }, []);
    return (
        <div className='bg-footer sticky'>
            {isMd ? (
                <p className='font-condensed text-center p-5 font-thin text-xs text-footerGrey'>
                <span className='text-white'>
                    - Proud to Use Indonesia
                    <img alt="Indonesian Flag" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg" className='w-4 inline-block align-middle mx-1 pb-0.5' width="4" height="4" loading='lazy'/>
                    Domain -
                </span>
                <br />
                <span className='text-white'>2023 © Created by Rifqi - All Rights Reserved.</span>
                </p>
            ):(
                <p className='font-condensed text-center p-5 font-thin text-xs text-footerGrey'>
                <span className='text-white'>
                    - Proud to Use Indonesia
                    <img alt="Indonesian Flag" src="https://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg" className='w-4 inline-block align-middle mx-1 pb-0.5' width="4" height="4" loading='lazy'/>
                    Domain -
                </span>
                <br />
                <span className='text-white'>2023 © Created by Rifqi - All Rights Reserved.</span>
                </p>
            )}
        </div>
    );
};

export default Footer;
