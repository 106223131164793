import React, { useEffect, useState } from 'react';
import { Turn as Hamburger } from 'hamburger-react';
import Logo from '../assets/WebsiteLogoNoBG.webp';
import { FaDownload, FaAngleDown } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import CV from '../assets/CV_MohammadRifqiFarhansyah.pdf';

const Header: React.FC = () => {
  const Links: Link[] = [
    { name: 'NEWS', link: 'news' },
    { name: 'PAPER', link: 'paper' },
    { name: 'AWARD', link: 'award' },
    { name: 'BLOG', link: '' },
  ];

  const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);
  const [open, setOpen] = useState<boolean>(false);
  const [showBlogDropdownHover, setShowBlogDropdownHover] = useState<boolean>(false);
  const [showBlogDropdownClick, setShowBlogDropdownClick] = useState<boolean>(false);
  const [hideTimeout, setHideTimeout] = useState<number | null>(null);
  const [angleRotation, setAngleRotation] = useState<number>(0); 

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
    const updateIsMd = () => {
      setIsMd(mediaQuery.matches);
    };

    mediaQuery.addListener(updateIsMd);
    return () => {
      mediaQuery.removeListener(updateIsMd);
    };
  }, []);

  const offset = isMd ? -100 : -100;
  const handleLogoClick = () => {
    window.location.href = 'https://rifqifarhansyah.id';
  };

  const blogClick = () => {
    setShowBlogDropdownClick(!showBlogDropdownClick);
    setAngleRotation(angleRotation === 0 ? 180 : 0);
  };
  
  const handleBlogDropdownLeave = () => {
    const timeoutId = window.setTimeout(() => {
      setShowBlogDropdownHover(false);
    }, 100);
    setHideTimeout(timeoutId);
  };

  const handleBlogDropdownEnter = () => {
    setShowBlogDropdownHover(true);
    clearTimeout(hideTimeout as number);
  };

  const hamburgerClick = () => {
    setShowBlogDropdownClick(false);
    setAngleRotation(0);
  };

  return (
    <div className="shadow-md w-screen fixed top-0 left-0 z-[10000]">
      <div className="md:flex items-center justify-between bg-white py-4 md:px-10 px-7">
        <div className="font-bold text-sm cursor-pointer flex items-center gap-1" onClick={handleLogoClick}>
          <img src={Logo} alt="Rifqi Website Logo" className="w-12 h-12" width="12" height="12" />
        </div>
        <div className="absolute right-6 top-4 cursor-pointer md:hidden my-auto" onClick={hamburgerClick}>
          <Hamburger toggled={open} toggle={setOpen}/>
        </div>
        <ul
          className={`md:flex md:shadow-none shadow-md md:items-center md:pb-0 pb-8 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-300 ${open ? 'top-12' : 'top-[-490px]'}`}
        >
          {Links.map((link) => (
            <li
              key={link.name}
              className="md:ml-8 md:my-0 my-7 font-semibold flex items-center font-condensed tracking-wider"
            >
              {link.name === 'BLOG' ? (
                <div className="relative"          
                  onMouseEnter={handleBlogDropdownEnter}
                  onMouseLeave={handleBlogDropdownLeave}
                >
                  <span
                    className={`text-sm text-gray-800 hover:text-textRed duration-500 flex items-center cursor-pointer transition-transform`}
                    onClick={blogClick}
                  >
                    <span className="inline-flex items-center">
                      {link.name}
                      <FaAngleDown
                        className={`md:hidden inline-flex ml-1 mb-0.5 transition-transform transform ${showBlogDropdownClick ? 'rotate-180' : ''}`}
                      />
                      <FaAngleDown
                        className={`md:inline-flex hidden ml-1 mb-0.5 transition-transform transform ${showBlogDropdownHover ? 'rotate-180' : ''}`}
                      />
                    </span>
                  </span>
                  <div className={`hover-dropdown absolute text-left text-sm top-full left-0 bg-white border-t-2 drop-shadow-md border-textRed rounded-md mt-2 py-2 transition-all duration-300 ease-in-out ${showBlogDropdownHover ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-[-8px]'}`}>
                    <RouterLink to="/safmc" className="md:block hidden px-4 py-2 text-gray-800 hover:text-textRed duration-500">
                      SAFMC
                    </RouterLink>
                  </div>
                  {/* {showBlogDropdownClick && (
                    <hr className="md:hidden border-t border-upArrow mt-5 mb-1 w-[80vw] absolute left-0 transition-all duration-300" />
                  )} */}
                  <div
                    className={`hover-click transition-all border-l-2 text-sm duration-300 ease-out transform relative ${showBlogDropdownClick ? 'opacity-100 max-h-20 translate-y-0' : 'opacity-0 max-h-0 translate-y-[-8px] overflow-hidden'}`}
                  >
                    <RouterLink to="/safmc" className="md:hidden block mt-3 ml-6 text-gray-800 hover:text-textRed duration-500">
                      SAFMC
                    </RouterLink>
                  </div>
                  {/* {showBlogDropdownClick && (
                    <hr className="md:hidden border-t border-upArrow mt-2 mb-2 w-[80vw] absolute left-0 transition-all duration-300" />
                  )} */}
                </div>
              ) : (
                <Link to={link.link} smooth={true} duration={200} offset={offset}>
                  <a
                    href={link.link}
                    className="text-sm text-gray-800 hover:text-textRed duration-500"
                    title={`Go to ${link.name}`}
                  >
                    {link.name}
                  </a>
                </Link>
              )}
            </li>
          ))}
          <li className="md:ml-8 md:my-0 hover:text-primary">
            <a
              href={CV}
              className={`inline-flex items-center font-condensed px-5 py-1 border-opacity-25 border text-sm font-thin hover:bg-hoverDarkGrey hover:border-opacity-50 rounded-full uppercase focus:outline-none ${
                open ? 'w-11/12 justify-center mr-8' : ''
              }`}
              title="Download CV here"
            >
              <FaDownload className="w-3 h-3 text-rgba-28-5-179 mr-2 " />
              CV (pdf)
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
