import React, { useEffect, useState } from 'react';
import CV from "../assets/CV_MohammadRifqiFarhansyah.pdf";
import SertifMagenta from '../assets/Sertifikat-Magenta.pdf';
import SertifPengkom from '../assets/SertifikatPengkom.pdf';
import SertifHanaBank from '../assets/Sertifikat_HanaBank.pdf';
import SentiLab from '../assets/SentiLab_Copyright.pdf';

const News: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };
    
        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    return(
        <div className=' flex flex-col items-center' >
            <br/>
            <p className='text-textGrey font-condensed text-center font-extrabold text-lg' id='news'>
            NEWS!
            </p>
            <p className='text-textGrey font-condensed text-center font-extralight text-lg'>
            Most Updated <a className='text-textRed hover:underline' href={CV}>CV</a>
            </p>
            <br/>
            {isMd ?(
            <ul className="text-textGrey text-md text-left font-condensed font-extralight w-[65.625rem] pl-5 h-auto list-disc">
                <li>
                    <div className='flex flex-row'>
                        <span>2025.01</span>
                        <span className='mx-1'>-</span>
                        <span>Our work, <a className='text-textRed hover:underline' href={SentiLab}>SentiLab-LLM</a>, is officially registered as a copyrighted work by Indonesia’s Ministry of Law.</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2025.01</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as one of five Probability and Statistics Course Assistant ~o~</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2024.09</span>
                        <span className='mx-1'>-</span>
                        <span>My undergraduate thesis will be affiliated with MBZUAI</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2024.01</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as Algorithm Strategies & Probability and Statistics Course Assistant!</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2023.12</span>
                        <span className='mx-1'>-</span>
                        <span>Joined Garuda ACE Lab remotely with Boston University!</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2023.11</span>
                        <span className='mx-1'>-</span>
                        <span>Got a scholarship from <a className='text-textRed hover:underline' href={SertifHanaBank}>HanaBank Korea</a>!</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2023.08</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as a Teaching Assistant for Linear Algebra and Geometry at ITB :D</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2023.04</span>
                        <span className='mx-1'>-</span>
                        <span>Received travel grant to attend Singapore Amazing Flying Machine Competition (<a className='text-textRed hover:underline' href="https://www.safmc.com.sg/">SAFMC</a>).</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2023.02</span>
                        <span className='mx-1'>-</span>
                        <span>Worked as part-time <a className='text-textRed hover:underline' href={SertifMagenta}>IT Infrastructure at Perum Perhutani.</a></span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2022.11</span>
                        <span className='mx-1'>-</span>
                        <span>Started a research project with University of Massachusetts, Amherst!</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2022.08</span>
                        <span className='mx-1'>-</span>
                        <span>Got <a className='text-textRed hover:underline' href={SertifPengkom}>my first Teaching Assistant opportunity</a> in USDI, ITB.</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2022.06</span>
                        <span className='mx-1'>-</span>
                        <span>Received AKABRI-70 Foundation Scholarship.</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2022.01</span>
                        <span className='mx-1'>-</span>
                        <span>Selected as TEEP Internship Student at BMW Lab, NTUST!</span>
                    </div>
                </li>
                <li>
                    <div className='flex flex-row'>
                        <span>2021.08</span>
                        <span className='mx-1'>-</span>
                        <span>Started my Bachelor journey in Institut Teknologi Bandung.</span>
                    </div>
                </li>
            </ul>
            ) : (
            <ul className="list-mobile text-textGrey text-sm text-left font-condensed font-extralight md:w-[95vw] sm:w-[93vw] h-auto list-disc">
                <li className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2025.01</span>
                        <span className='mx-1'>-</span>
                        <span>Our work, <a className='text-textRed hover:underline' href={SentiLab}>SentiLab-LLM</a>, is officially registered as a copyrighted work by Indonesia’s Ministry of Law.</span>
                    </div>
                </li>
                <li className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2025.01</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as one of five Probability and Statistics Course Assistant ~o~</span>
                    </div>
                </li>
                <li className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2024.09</span>
                        <span className='mx-1'>-</span>
                        <span>My undergraduate thesis will be affiliated with MBZUAI</span>
                    </div>
                </li>
                <li className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2024.01</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as Algorithm Strategies & Probability and Statistics Course Assistant!</span>
                    </div>
                </li>
                <li className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2023.12</span>
                        <span className='mx-1'>-</span>
                        <span>Joined Garuda ACE Lab remotely with Boston University!</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2023.11</span>
                        <span className='mx-1'>-</span>
                        <span>Got a scholarship from <a className='text-textRed hover:underline' href={SertifHanaBank}>HanaBank Korea</a>!</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2023.08</span>
                        <span className='mx-1'>-</span>
                        <span>Appointed as a Teaching Assistant for Linear Algebra and Geometry at ITB :D</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2023.04</span>
                        <span className='mx-1'>-</span>
                        <span>Received travel grant to attend Singapore Amazing Flying Machine Competition (<a className='text-textRed hover:underline' href="https://www.safmc.com.sg/">SAFMC</a>).</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2023.02</span>
                        <span className='mx-1'>-</span>
                        <span>Worked as part-time <a className='text-textRed hover:underline' href={SertifMagenta}>IT Infrastructure at Perum Perhutani.</a></span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2022.11</span>
                        <span className='mx-1'>-</span>
                        <span>Started a research project with University of Massachusetts, Amherst!</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2022.08</span>
                        <span className='mx-1'>-</span>
                        <span>Got <a className='text-textRed hover:underline' href={SertifPengkom}>my first Teaching Assistant opportunity</a> in USDI, ITB.</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2022.06</span>
                        <span className='mx-1'>-</span>
                        <span>Received AKABRI-70 Foundation Scholarship.</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2022.01</span>
                        <span className='mx-1'>-</span>
                        <span>Selected as TEEP Internship Student at BMW Lab, NTUST!</span>
                    </div>
                </li>
                <li  className='mx-10'>
                    <div className='flex flex-row'>
                        <span>2021.08</span>
                        <span className='mx-1'>-</span>
                        <span>Started my Bachelor journey in Institut Teknologi Bandung.</span>
                    </div>
                </li>
            </ul>
            )}
            <br/>
        </div>
    );
};

export default News