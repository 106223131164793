import React, { useEffect, useState } from 'react';

const Paper: React.FC = () => {
    const [isMd, setIsMd] = useState(window.matchMedia('(min-width: 67.5rem)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 67.5rem)');
        const updateIsMd = () => {
            setIsMd(mediaQuery.matches);
        };
    
        mediaQuery.addListener(updateIsMd);
        return () => {
            mediaQuery.removeListener(updateIsMd);
        };
    }, []);

    return(
        <div className=' flex flex-col justify-start items-left md:items-center' >
            <br/>
                <p className='text-textGrey font-condensed text-center font-extrabold text-lg' id="paper" >
                PAPER
                </p>
                <br/>
                {isMd ?(
                <div>
                    <h2 className='text-textGrey font-condensed text-left text-2xl'>
                    2025
                    </h2>
                    <ul className="text-textGrey text-md text-left font-condensed font-extralight max-w-6xl" style={{paddingLeft: '2rem', marginRight: '17.5rem', listStyleType: 'disc' }}>
                        <li>
                            <span className='text-textRed'><b>Do Language Models Understand Honorific Systems in Javanese?</b></span><br/>
                            Anonymous Author(s). <br/>
                            <span className='text-textRed'>Manuscript available upon request. <i>In submission</i>. </span> <br/>
                        </li>
                        <li>
                            <span className='text-textRed'><b>Crowdsource, Crawl, or Generate? Creating SEA-VL, a Multicultural Vision-Language Dataset for Southeast Asia.</b></span><br/>
                            Anonymous Author(s). <br/>
                            <span className='text-textRed'>Manuscript available upon request. <i>In submission</i>. </span> <br/>
                        </li>
                    </ul>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mt-4'>
                    2024
                    </h2>
                    <ul className="text-textGrey text-md text-left font-condensed font-extralight max-w-6xl"  style={{paddingLeft: '2rem', marginRight: '17rem', listStyleType: 'disc' }}>
                        <li>
                            <span className='text-textRed hover:underline'><a href='https://arxiv.org/abs/2411.09318'><b>DriveThru: a Document Extraction Platform and Benchmark Datasets for Indonesian Local Language Archives</b></a></span><br/>
                            <span className='font-bold'>M. Rifqi Farhansyah*</span>, M. Z. Fikri Johari*, A. Amiral, A. Purwarianti, K. A. Yuana, <span className='text-textRed'>D. Tanti Wijaya</span>. <br/>
                            <span className='text-textRed'>Garuda Academic and researCh of Excellence (Garuda ACE) Collaboration Project</span> <br/>
                            December 2024 <br/>
                        </li>
                    </ul>
                </div>
                ) : (
                <div className='md:w-[95vw] sm:w-[93vw] h-auto pr-2'>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mx-10'>
                    2025
                    </h2>
                    <ul className="text-textGrey text-sm text-left font-condensed font-extralight pl-8 list-disc">
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Do Language Models Understand Honorific Systems in Javanese?</b></span><br/>
                            Anonymous Author(s). <br/>
                            <span className='text-textRed'>Manuscript available upon request. <i>In submission</i>. </span> <br/>
                        </li>
                        <li className='mx-10'>
                            <span className='text-textRed'><b>Crowdsource, Crawl, or Generate? Creating SEA-VL, a Multicultural Vision-Language Dataset for Southeast Asia.</b></span><br/>
                            Anonymous Author(s). <br/>
                            <span className='text-textRed'>Manuscript available upon request. <i>In submission</i>. </span> <br/>
                        </li>
                    </ul>
                    <br/>
                    <h2 className='text-textGrey font-condensed text-left text-2xl mx-10'>
                    2024
                    </h2>
                    <ul className="text-textGrey text-sm text-left font-condensed font-extralight pl-8 list-disc ">
                        <li className='mx-10'>
                            <span className='text-textRed hover:underline'><a href='https://arxiv.org/abs/2411.09318'><b>DriveThru: a Document Extraction Platform and Benchmark Datasets for Indonesian Local Language Archives</b></a></span><br/>
                            <span className='font-bold'>M. Rifqi Farhansyah*</span>, M. Z. Fikri Johari*, A. Amiral, A. Purwarianti, K. A. Yuana, <span className='text-textRed'>D. Tanti Wijaya</span>. <br/>
                            <span className='text-textRed'>Garuda Academic and researCh of Excellence (Garuda ACE) Collaboration Project</span> <br/>
                            December 2024 <br/>
                        </li>
                    </ul>
                </div>
                )}
            <br/>
        </div>
    )
};

export default Paper
